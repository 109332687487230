class MerkzettelHinweis extends HTMLElement {
    constructor() {
        super();
        this.events = ["click"];
        this.addListener();
    }

    disconnectedCallback() {
        this.removeListener();
    }

    addListener() {
        for (const event of this.events) {
            window.addEventListener(event, this);
        }
    }

    removeListener() {
        for (const event of this.events) {
            window.removeEventListener(event, this);
        }
    }

    handleEvent(event) {
        this[`handle${event.type}`](event);
    }

    handleclick(event) {
        const { target } = event;
        if (target.getAttribute("cta") === "close") {
            this.close();
            this.remove();
        }
    }

    render(container, messageid) {
        const template = document.querySelector('template[id="merkzettel-hinweis"]');
        const clone = template.content.cloneNode(true);
        this.hinweis = clone.getElementById(messageid);
        this.appendChild(this.hinweis);
        container.appendChild(this);
    }

    open() {
        this.hinweis.setAttribute("animate", "");
    }

    close() {
        this.hinweis.removeAttribute("animate");
    }

    remove() {
        this.parentNode.removeChild(this);
    }
}

if (!customElements.get("merkzettel-hinweis")) {
    customElements.define("merkzettel-hinweis", MerkzettelHinweis);
}
